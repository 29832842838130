<template>
  <div class="page-box">
    <base-screen>
      <a-form-model layout="inline" :model="query">
        <template v-for="f in report.queryFields">
          <a-form-model-item v-if="!f.hidden" :key="f.key" :label="f.title" :prop="f.key">
            <search-field v-if="f.type === 'search'" :f="f" v-model="query[f.key]"></search-field>
            <a-input v-else-if="f.type === 'input'" :placeholder="f.placeholder" v-model="query[f.key]"></a-input>
            <date-range-field v-else-if="f.type === 'daterange'" v-model="query[f.key]" :f="f"></date-range-field>
            <select-field v-else-if="f.type === 'select'" v-model="query[f.key]" :f="f"></select-field>
          </a-form-model-item>
        </template>
        <a-form-model-item>
          <a-button type="primary" @click="handleQuery">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>

    <div class="tr" style="padding-bottom: 15px;">
      <a-button type="primary" @click="$router.push('/capital/invoice/commission/create')">开票</a-button>
    </div>

    <base-table
      :columns="report.resultFieldsPlain"
      :row-key="report.key"
      :data-source="data"
      :loading="loading"
      :pagination="page"
      @change="handleTableChange"
      :scroll="{x: 'auto'}"
      class="nowrap"
    >
      <template #operation="_, record">
        <a-button type="link" @click="openWin($res + record.invoicePic)">查看发票</a-button>
        <a-upload accept="image/png, image/jpeg" :show-upload-list="false" :custom-request="handleFileChange(record.invoiceRecordId)">
          <a-button :loading="uploading === record.invoiceRecordId" type="link">{{record.invoicePic ? '重新上传发票' : '上传发票'}}</a-button>
        </a-upload>
      </template>
      <template #invoiceType="_, record">
        增值税电子普通发票
      </template>
    </base-table>

    <a-modal v-model="modalVisible" :footer="null">
      <base-img :src="modalImg"></base-img>
    </a-modal>
  </div>
</template>

<script>
import { capital, common } from '@/api'
import { Report } from './report'
import { onMounted, ref, shallowReactive, watch, computed } from '@vue/composition-api'
import SearchField from './SearchField'
import DateRangeField from './DateRangeField'
import SelectField from './SelectField'
import { invoiceStates, invoiceTypes } from '@/utils/constants'
import { createMapFromList } from '@/utils/tools'
import { message } from 'ant-design-vue'
import ossUpload from '@/utils/oss'

export default {
  name: 'PageInvoiceCommission',
  components: {
    SearchField,
    DateRangeField,
    SelectField,
  },
  setup () {
    const queryFields = [
      {
        type: 'daterange',
        key: 'range',
        title: '申请时间',
      },
    ]

    const resultFields = [
      {
        key: 'applyTime',
        title: '申请时间',
      },
      {
        key: 'invoiceType',
        title: '发票类型',
        scopeFlag: true,
        // transform: x => createMapFromList(invoiceTypes)[x],
      },
      {
        key: 'invoiceTitle',
        title: '发票抬头',
      },
      {
        key: 'taxpayerNumber',
        title: '纳税人识别号',
      },
      {
        key: 'registerAddr',
        title: '注册地址',
      },
      {
        key: 'registerPhone',
        title: '注册电话',
      },
      {
        key: 'openBank',
        title: '开户银行',
      },
      {
        key: 'bankCardNo',
        title: '银行账号',
      },
      {
        key: 'invoiceAmount',
        title: '开票金额',
      },
      {
        key: 'email',
        title: '邮箱',
      },
      {
        key: 'invoiceState',
        title: '开票状态',
        transform: x => createMapFromList(invoiceStates)[x]
      },
      {
        key: 'operation',
        title: '操作',
        scopeFlag: true,
        fixed: 'right',
      }
    ]

    const report = new Report(queryFields, resultFields, {
      key: 'invoiceRecordId',
      queryApi: ({ range, ...others }) => {
        return capital.getCommissionInvoicePage({
          ...others,
          ...(range.length ? {
            applyBeginTime: range[0].format('YYYY-MM-DD 00:00:00'),
            applyEndTime: range[1].format('YYYY-MM-DD 23:59:59')
          } : null),
        })
      }
    })

    const query = ref(report.initialQuery)
    const data = ref([])
    const loading = ref(false)
    const page = shallowReactive({
      current: 1,
      size: 10,
      total: 0
    })

    onMounted(handleQuery)

    async function handleQuery () {
      loading.value = true
      const res = await report.query({
        ...query.value,
        current: page.current,
        size: page.size,
      })
      loading.value = false
      data.value = res.data
      page.total = res.page.total
    }

    function handleReset () {
      query.value = report.initialQuery
      handleQuery()
    }

    function handleTableChange ({ current }) {
      page.current = current
    }

    watch(() => page.current, value => {
      handleQuery()
    })

    const uploading = ref(false)
    function handleFileChange (invoiceRecordId) {
      return async function customRequest ({ file }) {
        uploading.value = invoiceRecordId
        const invoicePic = await ossUpload(file)
        const { code, msg } = await capital.uploadInvoice({
          invoicePic,
          invoiceRecordId,
        })
        uploading.value = ''
        if (code === '00000') {
          message.success('上传成功')
          handleQuery()
        } else {
          message.error(msg || '上传失败')
        }
      }
    }

    const modalImg = ref('')
    const modalVisible = ref(false)
    function openWin (url) {
      modalVisible.value = true
      modalImg.value = url
    }

    return {
      report,
      query,
      handleQuery,
      handleReset,
      loading,
      data,
      page,
      handleTableChange,
      uploading,
      modalImg,
      modalVisible,
      openWin,
      handleFileChange,
      $res: process.env.VUE_APP_IMG,
    }
  }
}
</script>

<style lang="css" scoped>

</style>
